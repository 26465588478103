import React from 'react';
import './App.css';
import '@aws-amplify/ui/dist/style.css';
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import AdminLayout from "./layouts/Admin/Admin.jsx";
import PaidLayout from "./layouts/Admin/Paid.jsx";
import Terms from "./views/pages/Terms.jsx";

const hist = createBrowserHistory();

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
      return (
        <Router history={hist}>
          <Switch>
            <Route path="/home" render={props => <AdminLayout {...props} />} />
            <Route path="/successful" render={props => <PaidLayout {...props} />} />
            <Route path="/terms" render={props => <Terms />} />
            <Route path="/card/:cardId" render={props => <AdminLayout cardId={props.match.params.cardId} {...props} />} />
            <Redirect path="/" to="/home" />
          </Switch>
        </Router>
      )
    
  }
}

export default App;
//export default withAuthenticator(App, true);

