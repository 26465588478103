import React from "react";
import ImageUpload from "../components/ImageUpload"
import backgroundImg from "../../assets/img/lounge.jpeg";
import VivaLogo, {VivaAirDiscountInfo, VivaDiscountRequirements, 
  VivaTitle, VivaCardTitle, VivaFeatures, VivaPartnershipLogo} from "../components/VivaLogo" 
import Amplify, { API, graphqlOperation, I18n } from "aws-amplify";
import * as queries from '../../graphql/queries';
import Buy from "./Buy"
import LogoGlobal from "../../assets/img/logo-global.png";
import aws_exports from '../../aws-exports';
// reactstrap components
import {
  Container,
  Row,
  Button,
  Col,
  Card,
  CardFooter,
  CardHeader,
  CardBody,
  CardTitle
} from "reactstrap";
import { ConsoleLogger } from "@aws-amplify/core";
import { faHourglass2 } from "@fortawesome/free-solid-svg-icons";
import reactDates from "react-dates";
Amplify.configure(aws_exports);
var _ = require('underscore');
class Home extends React.Component {
  constructor(props) {
    super(props);
    this.showCardsView = this.showCardsView.bind(this)
    this.state = {
      hasMoreCards: true,
      nextCardsToken: null,
      membership: '',
      currentDomain: window.location.hostname,
      selectedMembership: '',
      showButton: true,
      amount: '',
      cards: [],
      allCards: [],
      showCards: true
    }
  }

  componentDidMount() {
    console.log(this.state)
    this.getCards()
  }

  async getCards() {
    if (!this.state.hasMoreCards) return;
    
    let filter;
    if (['gln-cards.inflybo.com', 'localhost', 'live.dst9frn2eyfyi.amplifyapp.com'].includes(this.state.currentDomain)) {
      filter = {
        or: [{ type: { eq: 'For sale' } }, { type: { eq: 'Promotional' } }]
      };
    } else {
      filter = { partnerDomain: { eq: this.state.currentDomain } };
    }
  
    try {
      const response = await API.graphql(graphqlOperation(queries.listCardss, {
        nextToken: this.state.nextCardsToken,
        filter,
        limit: 1000
      }));
      
      const { items: cards, nextToken } = response.data.listCardss;
      this.setState(prevState => ({
        cards: [...prevState.cards, ...cards],
        allCards: [...prevState.allCards, ...cards],
        nextCardsToken: nextToken,
        hasMoreCards: Boolean(nextToken)
      }));
      
      if (nextToken) this.getCards();  // Handle pagination with more cards
    } catch (error) {
      console.error(error);
    }
  }

  handleClick(id) {
    const card = this.state.allCards.find(card => card.id === id);
    if (card) {
      this.setState({ 
        cards: [card],
        card,
        showPay: true,
        showButton: false
      });
    }
  }
  // test

  componentDidUpdate(prevProps, prevState) {
    if(prevState.allCards !== this.state.allCards) {
      this.handleCardFromUrl();
    }
  }

  // Check if the cardId is passed in the URL and filter the corresponding card
  handleCardFromUrl() {
    const { cardId } = this.props.match.params;
    if (cardId) {
      const cardMap = this.state.allCards.reduce((acc, card) => {
        acc[card.id] = card;
        return acc;
      }, {});
      const filteredCard = cardMap[cardId];
      
      if (filteredCard) {
        this.setState({
          cards: [filteredCard],
          card: filteredCard,
          showPay: true,
          showButton: false
        });
      }
    }
  }

  showCardsView(){
    this.setState({ 
      cards: this.state.allCards,
      showPay: false,
      showButton: true,
      showCards: true
    })
  }

  render() {    
    const Cards = () => {
      return _.sortBy(this.state.cards, 'order').map((card, i) => (
        <Col key={i} sm="6" md="6" lg="4" xl="3">
          <Card style={{ 
              backgroundColor: card.backgroundColor, 
              color: card.lettersColor, 
              alignItems: 'center',
              border: `2px solid ${card.backgroundColor}`, 
              boxShadow: '0px 0px 20px #1a8081', 
              borderRadius: "12px",  
              }}>

            <CardHeader>
              <VivaLogo card={card} /> 

              {(this.state.currentDomain === "viva-cards.inflybo.com") ? (
                <VivaCardTitle cardTitle={card.name}/>
              ) : (
                <>
                  <h4 style={{ fontWeight: "bold", textAlign: "center", margin: "5px auto 10px"}}>{card.name} </h4>
                </>
              )}

              {/* card discount info */}
              <VivaAirDiscountInfo card={card} />
            </CardHeader>

            {/* CARD BODY */}
            <CardBody>
              <div className="card-icon icon-primary">
                {(this.state.currentDomain === "viva-cards.inflybo.com") ? (
                  <VivaPartnershipLogo />
                ) : (
                  <ImageUpload imageKey={card.imageKey} updatePhoto={this.updatePhoto} />
                )}
              </div>
              <Row style={{ justifyContent: 'center' }} >
                {card.type !== 'Promotional' ? (
                  <CardTitle tag="h3" className="mt-3">${card.price}</CardTitle>
                ) : null}
              </Row>
              { /* card features */
                (this.state.currentDomain !== "viva-cards.inflybo.com") ? 
                (<ul style={{paddingLeft: "20px", fontSize: "0.97em"}}>
                  {card.features.map((feature, i) => ( <li key={i}>{feature}</li> ))}
                </ul>) : 
                (
                  <>
                    <p style={{
                      color:'red',
                      fontWeight:'bold',
                      textAlign:'center'
                    }}>{card.features[1]}</p>
                    <VivaFeatures />
                  </>
                )
              }
            </CardBody>

            {/* CARD FOOTER */}
            {this.state.showButton ? (
              <CardFooter>
                <Button
                  className="btn btn-md btn-round mb-2"
                  color="info"
                  onClick={() => this.handleClick(card.id)}
                >
                  {(card.partnerDomain === "viva-cards.inflybo.com") 
                    ? I18n.get('Compra aqui') 
                    : I18n.get('buy')}
                </Button>
              </CardFooter>
            ) : null}
          </Card>
        </Col>
      ))
    }
    const styleIfViva = (this.state.currentDomain === "viva-cards.inflybo.com") ? "center" : ""; 
    let vivaBackground = {}; 

    if(!(this.state.currentDomain === 'gln-cards.inflybo.com')) {
      vivaBackground = {
          backgroundImage: `url('${backgroundImg}')`,
          backgroundPosition: 'center', 
          backgroundSize: 'cover', 
          backgroundRepeat: 'no-repeat', 
          backgroundHeight:'100%',
          height: '100%',
          boxShadow:'inset 0 0 0 2000px rgba(255,255,255,0.88)',
      };
    }

    return (
      <div className="content" style={vivaBackground}>
          <Col sm="12" md="11" lg="10" className="ml-auto mr-auto mt-3">
            <VivaTitle currentDomain={this.state.currentDomain}/>
            <br />
            <Row style={{justifyContent:styleIfViva}}>
              {this.state.showCards ? ( <Cards /> ) : null}
              {this.state.showPay ? ( <Buy card={this.state.card} /> ) : null}
            </Row>
          </Col>
          {this.state.showPay ? (
            <Row style={{ justifyContent: "center" }}>
              <Button outline color="info" className="btn-round" onClick={this.showCardsView}>{I18n.get('seeCards')}</Button>
            </Row>
          ) : null}
          {this.state.showCards && !this.state.showPay ? (
            <Col sm="12" md="11" lg="10" className="ml-auto mr-auto mt-4">
              <Row style={{ justifyContent: "center" , textAlign: "justify"}}>
                <>
                  <Card>
                    <CardBody>
                      <VivaDiscountRequirements currentDomain={this.state.currentDomain} />
                      <p>{I18n.get('intro')}</p>
                    </CardBody>
                  </Card>
                </>
              </Row>
            </Col>
          ) : null}
      </div>
    );
  }
}

export default Home; 